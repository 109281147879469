"use strict";
/**
 * Global variables
 */
const debounce = require("lodash/debounce");
let prevScrollPos = window.scrollY;
const header = document.querySelector(".header-sticky");

/**
 * Hides the header on scroll
 * @function
 * @returns {void}
 */
const hideHeaderOnScroll = () => {
    const currentScrollPos = window.scrollY;

    if (prevScrollPos > currentScrollPos) {
        header.style.top = "-1px";
    } else {
        header.style.top = `-${header.offsetHeight}px`;
    }

    prevScrollPos = currentScrollPos;
};

/**
 * Checks header state on page load
 * @function
 * @returns {void}
 */
const checkHeaderState = () => {
    if (window.scrollY === 0) {
        header.style.top = "-1px";
    }
};

/**
 * Binds events for the header element
 * @returns {void}
 */
const bindEvents = () => {
    if (header) {
        checkHeaderState();
        window.addEventListener("scroll", debounce(hideHeaderOnScroll, 50), {passive: true});
    }
};

module.exports = {
    bindEvents
};
