// ==============================
//* MAIN
// Overrides app_storefront_base
//? This file gathers all the project main scripts.
// ==============================

window.jQuery = window.$ = require("jquery");
var processInclude = require("base/util");

$(document).ready(function () {
    processInclude(require("./components/menu"));
    processInclude(require("base/components/cookie"));
    processInclude(require("base/components/consentTracking"));
    processInclude(require("./components/footer"));
    processInclude(require("./components/miniCart"));
    processInclude(require("./components/fakeSelect"));
    processInclude(require("base/components/collapsibleItem"));
    processInclude(require("./components/search"));
    processInclude(require("./components/clientSideValidation"));
    processInclude(require("base/components/countrySelector"));
    processInclude(require("base/components/toolTip"));
    processInclude(require("./components/header"));
    processInclude(require("./components/topBanner"));
    processInclude(require("./components/lazyLoad"));
    processInclude(require("./components/showFooterList"));
    processInclude(require("./components/simpleSlider"));
    processInclude(require("./components/cookiesBtn"));
    processInclude(require("./components/stickyHeader"));
});

// Slick carousel
require("/node_modules/slick-carousel/slick/slick.min.js");

// Base
require("base/thirdParty/bootstrap");
require("base/components/spinner");
